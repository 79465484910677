.signed-in {
    padding: 96px 0;
}
.navbar-toggler {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: none;
}
