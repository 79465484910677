.signed-out {
    height: 100vh;
}
.form-signin {
    width: 100%;
    max-width: 328px;
}
.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.signin-error {
    min-height: 6.5em;
}
