.stream-preview {
    cursor: pointer;
}
.stream-preview__icon {
    width: 1em;
}
.animate-spin {
    -moz-animation: spin 0.8s infinite linear;
    -o-animation: spin 0.8s infinite linear;
    -webkit-animation: spin 0.8s infinite linear;
    animation: spin 0.8s infinite linear;
    display: inline-block;
}

@-moz-keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
@-webkit-keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
@-o-keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
@-ms-keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}
@keyframes spin {
    0% {
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.hidden {
    display: none !important;
    visibility: hidden;
}
